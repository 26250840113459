window.onscroll = function () {
    scrollFunction();
};

//shrink and grows nav on certain scroll height
function scrollFunction() {
    const menuLinks = document.querySelectorAll('.menuLink');
    const locPicker = document.querySelectorAll('.locale-picker');

    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        for (let i = 0, ii = menuLinks.length; i < ii; i++) {
            menuLinks[i].classList.add('shrunk');
        }
        // for (let i2 = 0, ii2 = locPicker.length; i2 < ii2; i2++) {
            // locPicker[i2].classList.add('hidden');
        // }
        //document.getElementById('desktop-menu').style.height = '6rem';
        document.getElementById('desktop-menu').classList.add('shrunk');
    } else {
        for (let e = 0, ee = menuLinks.length; e < ee; e++) {
            menuLinks[e].classList.remove('shrunk');
        }
        // for (let e2 = 0, ee2 = locPicker.length; e2 < ee2; e2++) {
            // locPicker[e2].classList.remove('hidden');
        // }
        //document.getElementById('desktop-menu').style.height = '9rem';
        document.getElementById('desktop-menu').classList.remove('shrunk');
    }
}
