'use strict';

import { $on, qs, qsa, $get } from './modules/utils';
import '../scss/styles.scss';
import './navigation';
import './modules/scroll-to-top.js';

$on(document, 'DOMContentLoaded', () => {
    /**
     * select art in contact form
     */
    let art = $get('art');

    if (art !== null && qs('.custom-form')) {
        qs('.custom-form option[value="' + art + '"]').selected = true;
    }

    /**
     * select vehicle in contact form
     */
    let vehicle = $get('vehicle');

    if (vehicle !== null && qs('.custom-form')) {
        qs('.custom-form option[value="' + vehicle + '"]').selected = true;
    }

    if (qs('#input_8357151') && qs('.newsletter_submit')) {
        qs('.newsletter_submit').setAttribute('disabled', 'disabled');

        $on(qs('#input_8357151'), 'change', (ev) => {
            if (ev.target.checked == true) {
                qs('.newsletter_submit').removeAttribute('disabled');
            } else {
                qs('.newsletter_submit').setAttribute('disabled', 'disabled');
            }
        });
    }

    /**
     * add asterisks to required placeholders
     */
    // if (qs('[required')) {
    //     qsa('[required]').forEach((el) => {
    //         el.setAttribute('placeholder', el.getAttribute('placeholder') + '*');
    //     });
    // }

    // if (qs('select.form-control')) {
    //     qsa('select.form-control').forEach((el) => {
    //         let elm = qs('option', el);
    //         elm.innerText += '*';
    //     });
    // }

    // if (qs('#contact-form button[type="submit"]')) {
    //     qs('#contact-form button[type="submit"]').setAttribute('disabled', true);
    //     qs('#contact-form button[type="submit"]').style.display = 'none';
    // }
});
